@font-face {
  font-family: WhyteInktrapRegular;
  src: url("./assets/fonts/ABCWhyteInktrap-Regular.woff2") format("woff2"),
    url("./assets/fonts/ABCWhyteInktrap-Regular.woff") format("woff");
}

@font-face {
  font-family: PPFraktionMonoRegular;
  src: url("./assets/fonts/PPFraktionMono-Regular.woff") format("woff");
}

@font-face {
  font-family: PTRootUIBold;
  src: url("./assets/fonts/pt-root-ui_bold.woff2") format("woff2"), url("./assets/fonts/pt-root-ui_bold.woff") format("woff");
}

@font-face {
  font-family: PTRootUIMedium;
  src: url("./assets/fonts/pt-root-ui_medium.woff2") format("woff2"), url("./assets/fonts/pt-root-ui_medium.woff") format("woff");
}

html {
  background-color: #fff;
}

body {
  margin: 0;
  overflow-x: hidden;
  vertical-align: baseline;
  font-weight: normal;
  font-style: normal;
  font-family: WhyteInktrapRegular, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
  font-family: WhyteInktrapRegular, Helvetica, Arial, sans-serif;
}

a {
  text-decoration: none;
  color: inherit;
}

h1,
h2,
h3,
h4,
h5 {
  font-weight: normal;
  margin: 8px 0;
}

h1 {
  font-size: 56px;
  line-height: 100%;
}

h2 {
  font-size: 40px;
  line-height: 115%;
}

h3 {
  font-size: 28px;
  line-height: 150%;
}

h4 {
  font-size: 24px;
  line-height: 130%;
}

h5 {
  font-family: PPFraktionMonoRegular;
  font-size: 20px;
  line-height: 120%;
}

h6 {
  font-size: 18px;
  line-height: 150%;
}

p,
div {
  font-size: 16px;
  line-height: 150%;
}

span {
  font-size: inherit;
  font-family: inherit;
}

p {
  margin: 8px 0;
}

input:-webkit-autofill,
input:-webkit-autofill:focus {
  transition: background-color 0s 600000s, color 0s 600000s !important;
}

.frame-mock-up .lazy-load-image-background {
  position: absolute;
  width: 100%;
  height: 100%;
}

.carousel-root {
  height: 100%;
}

.carousel.carousel-slider {
  overflow: visible !important;
  height: 100%;
}

.carousel.carousel-slider .slider-wrapper {
  height: 100%;
}

.carousel.carousel-slider .slider {
  padding-left: 38px;
  height: 100%;
}

@media screen {
  .carousel.carousel-slider .slider {
    padding-left: 12px;
  }
}

.carousel.carousel-slider .slider .slide {
  padding: 0 16px;
}

.product-carousel .carousel.carousel-slider .slider {
  padding-left: 0;
}

.product-carousel .carousel.carousel-slider .slider .slide {
  padding: 0;
}

.product-carousel .carousel .control-dots .dot {
  box-shadow: none;
  background: #fff;
  opacity: 1;
  margin: 0 4px;
}

.product-carousel .carousel .control-dots .dot.selected {
  box-shadow: none;
  background: #000;
}

.img-zoom-lens {
  width: 40px;
  height: 40px;
  z-index: 1;
}

.img-zoom-result {
  border: 1px solid #d4d4d4;
  position: absolute;
  z-index: 100;
  border-radius: 100%;
  width: 300px;
  height: 300px;
  pointer-events: none;
  background-repeat: no-repeat;
  display: none;
}

#klarna-placement-credit-promotion-badge::part(osm-container) {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  justify-content: center;
}

#klarna-placement-credit-promotion-badge::part(osm-legal) {
  display: none;
}

#klarna-placement-credit-promotion-badge::part(osm-message) {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

#klarna-placement-credit-promotion-badge::part(osm-cta) {
  color: #999999;
}

.MuiDataGrid-root--noToolbar .MuiDataGrid-main > div:last-child {
  color: transparent !important;
}
